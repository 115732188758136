* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  color: #fff;
  font-family: Helvetica;
}

.bg-pri {
  background-color: red !important;
  color: #fff;
}

.text-pri {
  color: red !important;
}

.btn-outline-pri {
  border: 1px solid red;
}
