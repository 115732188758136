.cus-nav {
  background-color: transparent;
  color: #fff;

  .link-style {
    color: #fff !important;
    margin-left: 1rem;
    font-weight: bold;
  }

  .nav-logo {
    height: 80px;
  }
}

.linkh {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
}
.linkh:after {
  display: block;
  content: "";
  border-bottom: solid 3px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.linkh:hover:after {
  transform: scaleX(1);
}
.linkh.fromRight:after {
  transform-origin: 100% 50%;
}
.linkh.fromLeft:after {
  transform-origin: 0% 50%;
}

.home {
  height: 92vh;
}
.showcase {
  height: 60%;
  display: block;
  background-image: url("../../assets/newbanner.jpeg");
  background-position: 50% 50%;
  background-size: cover;
}

.showcase-title {
  color: red;
  font-size: 3rem;
  font-weight: bold;
}
.showcase-subtitle {
  font-size: 1.3rem;
}

.gallery {
  margin-top: 10rem;
}

.buydiv {
  height: 32%;
}

.about {
  margin-top: 10rem;
  .aboutimg {
    display: flex;
    justify-content: center;
    .about-img {
      border-radius: 16px;
    }
  }

  .subtitle {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.banner {
  margin-top: 10rem;

  .showcase-img {
    width: 100vw;
  }
}

.si {
  height: 32px;
}

.roadmap {
  margin-top: 8rem;
  .r-content {
    margin-top: 6rem;
  }
  .title {
    font-weight: bold;
  }
  .text-wrapper {
    margin-top: 2rem;

    .number-text {
      color: orange;
      font-size: 2rem;
      font-weight: bold;
    }

    .subtitle {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
}

.team {
  margin-top: 8rem;

  .team-img {
    border-radius: 10px;
  }

  .sub {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.faq {
  margin-top: 10rem;
}

.si {
  height: 21px;
}

@media only screen and (max-width: 600px) {
  .nav-logo {
    height: auto !important;
  }
  .home {
    height: auto;
  }
  .showcase {
    height: 300px;
    background-position: left;
  }

  .team-img {
    margin-top: 1rem;
  }
}
